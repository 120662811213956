<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>

        <el-row class='table-search'>
          <el-col :span='24'>
            <el-form :inline='true' :model='searchCondition'>
              <el-form-item label=''>
                <el-input v-model='searchCondition.nickname' placeholder='用户昵称' clearable></el-input>
              </el-form-item>
              <el-form-item label=''>
                <el-input v-model='searchCondition.mobile' placeholder='手机号' clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' @click='handleQuery'>查询</el-button>
              </el-form-item>

            </el-form>
          </el-col>
        </el-row>
        <div class='default-table'>
          <!--    渲染表格-->
          <el-table :loading='loadingStatus' :data='dataList' @sort-change='changeTableSort' border>
            <el-table-column label='序号' type='index' width='100'></el-table-column>
            <el-table-column label='账号' prop='username' width='220'>
              <template slot-scope='{row}'>
                <el-tag effect='plain' :type='row.userid?"success":"danger"'>{{ row.userid ? '已绑定钉钉' : '未绑定钉钉' }}
                </el-tag>
                <br />
                <span>账号：{{ row.username || '' }}</span> <br />
                <span>昵称：{{ row.nickname || '' }}</span><br />
                <span>姓名：{{ row.name || '' }}</span><br />
                <span>手机：{{ row.mobile || '' }}</span><br />
                <div>
                  当前角色：
                  <div v-if='row.role_names'>
                    <el-tag style='margin:2px' effect='plain' v-for='(role_name,index) in row.role_names' :key='index'>
                      {{ role_name || '' }}
                    </el-tag>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label='权限'>
              <template slot-scope='{row}'>
                <div v-if='row.permission_ids' style='height: 400px;overflow-y: scroll'>
                  <PermissionMap :permissions-list='permissionsList'
                                 :checked-node-ids='row.permission_ids'></PermissionMap>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import { mapGetters } from 'vuex'
import UserLoginLog from '@/pages/user/components/UserLoginLog'
import PieChart from '@/pages/dashboard/PieChart'
import PermissionMap from '@/pages/user/components/PermissionMap'
import { handleTree } from '@/utils/ruoyi'

export default {
  name: 'UserPurview',
  components: {
    PermissionMap,
    PieChart,
    UserLoginLog,
    PageHeaderLayout,
    ApeTable

  },
  data() {
    return {
      loadingStatus: false,
      // 搜索条件
      searchCondition: { nickname: null, mobile: null },
      // 分页信息
      pagingData: {
        current_page: 1,
        page_size: 10
      },
      total: 0,
      // 分页的offset,序号列使用
      offset: 0,
      dataList: [],
      permissionsList: [],
      defaultProps: {
        children: 'children',
        label: 'display_name'
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()

    },
    async getList() {
      this.dataList = []
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getUserPurviewList(searchCondition)
      this.dataList = list || []
      this.total = pages.total
      this.offset = pages.offset
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
      this.loadingStatus = false
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((k) => {
        if (this.searchCondition[k])
          condition[k] = this.searchCondition[k]
      })
      // if (this.searchCondition.nickname) {
      //   condition.nickname = this.searchCondition.nickname
      // }

      // if (this.searchCondition.login_method) {
      //   condition.login_method = this.searchCondition.login_method
      // }
      // if (this.searchCondition.real_ip) {
      //   condition.real_ip = this.searchCondition.real_ip
      // }
      // if (this.searchCondition.time_value) {
      //   condition.start_time = this.searchCondition.time_value[0]
      //   condition.end_time = this.searchCondition.time_value[1]
      // }
      return condition
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },
    async loadPermissionsList() {

      let { list } = await this.$api.getUserPermissionRange()
      this.permissionsList = handleTree(list, 'id', 'parent_id', 'children')

      // this.permissionsList = JSON.parse(JSON.stringify(list))
      // this.permissionsList = JSON.parse(JSON.stringify(list)).map(item => { // 展示数据
      //   // hasChildren 表示需要展示一个箭头图标
      //   item.hasChildren = item.children && item.children.length > 0
      //   // 只展示一层
      //   // 如果有children数据，会自动加载，就不是懒加载了，也可以配置tree-props里面的children为其他字段
      //   item.children = null
      //   // 记住层级关系
      //   item.idList = [item.id]
      //   return item
      // })


    }
  },
  created() {
    this.loadPermissionsList()
    this.handleQuery()
  }
}
</script>

<style scoped>
.chart-box {
  width: 50%;

}
</style>
