<template>
  <div>
    <el-tree
        ref='tree'
        :data='permissionsList'
        :default-checked-keys="checkedNodeIds"
        :props='defaultProps'
        node-key='id'
        show-checkbox
      >
    </el-tree>
<!--    {{ newData }}-->
<!--    <el-tree-->
<!--        ref='tree'-->
<!--        :data='newData'-->
<!--        :props='defaultProps'-->
<!--        node-key='id'-->
<!--        style="width: 100px;display: inline-block;border: 1px solid blue">-->
<!--    </el-tree>-->
  </div>
</template>

<script>
export default {
  name: 'PermissionMap',
  props: {
    permissionsList: {
      type: Array,
      default() {
        return []
      }
    },
    checkedNodeIds:{
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'display_name'
      },
      newData: []
    }
  },
  methods: {
    setCheckedKeys() {
      // this.$refs.tree.setCheckedKeys(this.checkedNodeIds);
    },
    // findParents(treeData, id) {
    //   let allparents = []
    //   if (treeData.length === 0) {
    //     return
    //   }
    //   // 定义一个函数
    //   let findUp = (data, id) => {
    //     // 没有id就退出
    //     if (!id) return
    //     data && data.forEach(item => {
    //       if (item.id && item.id == id) {
    //         allparents.unshift(item.id)
    //         findUp(treeData, item.parentId)
    //       } else {
    //         if (!!item.children) {
    //           findUp(item.children, id)
    //         }
    //
    //       }
    //     })
    //   }
    //   // 调用函数
    //   findUp(treeData, id)
    //   return allparents
    // }
    //
    // mapData(data) {
    //   //   data.forEach((item) => {
    //   //     let data = this.checkedNodeIds.indexOf(item.id)
    //   //     if (data === 0) {
    //   //       this.newData.push(item)
    //   //     }
    //   //     if (item.children) {
    //   //       this.mapData(item.children)
    //   //     }
    //   //   })
    //   // }
    //   data.forEach((item) => {
    //     if (item.children) {
    //       this.mapData(item.children)
    //     } else {
    //           let data = this.checkedNodeIds.indexOf(item.id)
    //           if (data === -1) {
    //             data.splice(index, 1)
    //           }
    //       //这里就是最后一层child 如果没有id就删掉item
    //
    //     }
    //   })
    // }
  },
  mounted() {
    // this.newData = JSON.parse(JSON.stringify(this.permissionsList))
    // this.mapData(this.newData)
    // console.log(this.newData)


  }
}
</script>

<style scoped>

</style>
